import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    IconButton,
    Modal,
    Tooltip,
    Typography
} from '@mui/material';
import { ReactTable, Filter, InfoModal } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table';
import { Link, useNavigate } from 'react-router-dom';
import { GAS_PATH, showToast } from '@utils';
import { Buy } from './Buy';
import { Sell } from './Sell';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {
    apiGetWatchlist,
    apiSaveToWatchlist,
    apiGetTheEur,
    apiGetMinMaxDisplay
} from '@store';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const EUR = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const { limitOrder } = useSelector((state) => state.notification);

    const { theeur, minMaxDisplay, trailingStopForDisplay, disableTrading } =
        useSelector((state) => state.the);

    const disableTradingStatus = disableTrading?.disable;

    // filter modal
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const handleOpenFilterModal = () => setOpenFilterModal(true);
    const handleCloseFilterModal = () => setOpenFilterModal(false);

    const dataWithTS = useMemo(() => {
        const lastPrices = {};

        theeur?.symbols?.forEach((symbol) => {
            let lastBidPrice = null;
            for (let i = limitOrder?.length - 1; i >= 0; i--) {
                if (
                    limitOrder[i]?.contract === symbol &&
                    limitOrder[i]?.type === 'bid' &&
                    limitOrder[i]?.market === 'THE'
                ) {
                    lastBidPrice = limitOrder[i];
                    break;
                }
            }

            let lastAskPrice = null;
            for (let i = limitOrder?.length - 1; i >= 0; i--) {
                if (
                    limitOrder[i]?.contract === symbol &&
                    limitOrder[i]?.type === 'ask' &&
                    limitOrder[i]?.market === 'THE'
                ) {
                    lastAskPrice = limitOrder[i];
                    break;
                }
            }

            lastPrices[symbol] = {
                lastBidPrice:
                    lastBidPrice?.price_gt || lastBidPrice?.price_lt || 0,
                lastAskPrice:
                    lastAskPrice?.price_gt || lastAskPrice?.price_lt || 0
            };
        });

        const tempData =
            theeur?.symbols?.map((symbol) => {
                try {
                    return {
                        contract: symbol,
                        lastBidPrice: lastPrices[symbol]?.lastBidPrice,
                        lastAskPrice: lastPrices[symbol]?.lastAskPrice,
                        ...theeur?.contracts[symbol],
                        ...minMaxDisplay[symbol],
                        ...trailingStopForDisplay[symbol]
                    };
                } catch (error) {
                    return {
                        contract: symbol,
                        lastBidPrice: 0,
                        lastAskPrice: 0,
                        ...theeur?.contracts[symbol],
                        ...minMaxDisplay[symbol],
                        ...trailingStopForDisplay[symbol]
                    };
                }
            }) || [];
        return tempData;
    }, [theeur, minMaxDisplay, trailingStopForDisplay, limitOrder]);

    const [selectedContract, setSelectedContract] = useState(null);

    // info modal
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const handleOpenInfoModal = () => { setOpenInfoModal(true); }
    const handleCloseInfoModal = () => { setOpenInfoModal(false); }

    // buy modal
    const [openBuyModal, setOpenBuyModal] = useState(false);
    const handleOpenBuyModal = useCallback((selectedContract) => {
        setSelectedContract(selectedContract);
        setOpenBuyModal(true);
    }, []);
    const handleCloseBuyModal = () => setOpenBuyModal(false);

    // sell modal
    const [openSellModal, setOpenSellModal] = useState(false);
    const handleOpenSellModal = useCallback((selectedContract) => {
        setSelectedContract(selectedContract);
        setOpenSellModal(true);
    }, []);
    const handleCloseSellModal = useCallback(() => setOpenSellModal(false), []);

    // add to watchlist
    const handleAddToWatchlist = useCallback(
        async (symbol, product) => {
            try {
                const response = await dispatch(
                    apiSaveToWatchlist({ symbol, product })
                );
                if (response.meta.requestStatus === 'fulfilled') {
                    showToast('success', 'Added to watchlist');
                    dispatch(apiGetWatchlist());
                    dispatch(apiGetTheEur());
                    dispatch(apiGetMinMaxDisplay());
                } else if (response.meta.requestStatus === 'rejected') {
                    showToast('error', response.error.message);
                }
            } catch (error) {
                showToast('error', error.message);
            }
        },
        [dispatch]
    );

    // go to details
    const handleGoToDetails = (contract) => {
        navigate(
            `${GAS_PATH.THE_CONTRACT_DETAILS}?sym=${contract}&currency=eur&product=THE`
        );
    };

    const columns = useMemo(
        () => [
            {
                header: 'Contract',
                id: 'contract',
                accessorFn: ({ contract }) => contract || '',
                Cell: ({ renderedCellValue }) => (
                    <Typography
                        sx={{
                            color: '#3B9D9D',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            pl: '8px'
                        }}
                    >
                        {renderedCellValue}
                    </Typography>
                ),
                size: 50
            },
            {
                header: 'Bid',
                id: 'bid',
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography>{row.original?.bid}</Typography>
                        <Typography
                            sx={{
                                opacity: 0.5,
                                fontSize: '14px',
                                marginLeft: '4px'
                            }}
                        >
                            {row?.original?.lastBidPrice === 0
                                ? ''
                                : row?.original?.lastBidPrice}
                        </Typography>
                    </Box>
                ),
                size: 50
            },
            {
                header: 'Ask',
                id: 'ask',
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography>{row.original?.ask}</Typography>
                        <Typography
                            sx={{
                                opacity: 0.5,
                                fontSize: '14px'
                            }}
                        >
                            {row?.original?.lastAskPrice === 0
                                ? ''
                                : row?.original?.lastAskPrice}
                        </Typography>
                    </Box>
                ),
                size: 50
            },
            {
                header: 'Max',
                id: 'maxbuy',
                Cell: ({ row }) => (
                    <Box onClick={(e) => e.stopPropagation()}>
                        {row?.original?.maxbuy ? (
                            <Link
                                to={GAS_PATH.NOTIFICATIONS}
                                className="underline cursor-pointer text-sky-600"
                            >
                                {`B:${row?.original?.maxbuy?.toFixed(3)}`}
                            </Link>
                        ) : (
                            <Link
                                to={GAS_PATH.NOTIFICATIONS}
                                className="underline cursor-pointer text-sky-600"
                            >
                                {row?.original?.maxsell
                                    ? `S:${row?.original?.maxsell?.toFixed(3)}`
                                    : ''}
                            </Link>
                        )}
                    </Box>
                ),
                size: 50
            },
            {
                header: 'Min',
                id: 'minbuy',
                Cell: ({ row }) => (
                    <Box onClick={(e) => e.stopPropagation()}>
                        {row?.original?.minbuy ? (
                            <Link
                                to={GAS_PATH.NOTIFICATIONS}
                                className="underline cursor-pointer text-sky-600"
                            >
                                {`B:${row?.original?.minbuy?.toFixed(3)}`}
                            </Link>
                        ) : (
                            <Link
                                to={GAS_PATH.NOTIFICATIONS}
                                className="underline cursor-pointer text-sky-600"
                            >
                                {row?.original?.minsell
                                    ? `S:${row?.original?.minsell?.toFixed(3)}`
                                    : ''}
                            </Link>
                        )}
                    </Box>
                ),
                size: 50
            },
            {
                header: 'Ceiling',
                id: 'ceiling',
                Cell: ({ row }) => (
                    <Link
                        to={GAS_PATH.NOTIFICATIONS}
                        className="underline cursor-pointer text-sky-600"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {row?.original[0]?.ceiling.toFixed(3)}
                    </Link>
                ),
                size: 50
            },
            {
                header: 'Floor',
                id: 'floor',
                Cell: ({ row }) => (
                    <Link
                        to={GAS_PATH.NOTIFICATIONS}
                        className="underline cursor-pointer text-sky-600"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {row?.original[0]?.floor.toFixed(3)}
                    </Link>
                ),
                size: 50
            },
            {
                header: 'Updated At',
                id: 'updated_at_cet_str',
                accessorFn: ({ updated_at_cet_str }) =>
                    updated_at_cet_str || '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            }
        ],
        []
    );

    // start of buy sell restriction modal
    // convert user.StartTime and user.EndTime string to hh:mm
    const startTime = user?.StartTime?.split(':').slice(0, 2).join(':')
    const endTime = user?.EndTime?.split(':').slice(0, 2).join(':')
    const lunchStart = user?.LunchStart?.split(':').slice(0, 2).join(':')
    const lunchEnd = user?.LunchEnd?.split(':').slice(0, 2).join(':')

    // convert into berlin time
    // if current time is between startTime and endTime and not between lunchStart and lunchEnd then show modal
    var europeTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" });
    var time = new Date(europeTime);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    // Format current time like startTime and endTime (hh:mm)
    var currentTime = `${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;
    // end of buy sell restriction modal

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box
                        className="flex gap-4 items-center justify-center"
                        key={'button'}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {disableTradingStatus === false &&
                            user?.HideBuyButton === false && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: '1px solid #27A9FF',
                                        color: '#27A9FF',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: '1px solid #27A9FF',
                                            color: 'white',
                                            backgroundColor: '#27A9FF'
                                        }
                                    }}
                                onClick={() => {
                                    if (startTime <= currentTime && currentTime <= endTime && (currentTime < lunchStart || currentTime > lunchEnd)) {
                                        handleOpenBuyModal(row.original)
                                    } else {
                                        handleOpenInfoModal()
                                    }
                                }}
                                >
                                    Buy
                                </Button>
                            )}
                        {disableTradingStatus === false &&
                            user?.HideSellButton === false && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: '1px solid #EB6969',
                                        color: '#EB6969',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: '1px solid #EB6969',
                                            color: 'white',
                                            backgroundColor: '#EB6969'
                                        }
                                    }}
                                onClick={() => {
                                    if (startTime <= currentTime && currentTime <= endTime && (currentTime < lunchStart || currentTime > lunchEnd)) {
                                        handleOpenSellModal(row.original)
                                    } else {
                                        handleOpenInfoModal()
                                    }
                                }}
                                >
                                    Sell
                                </Button>
                            )}
                        <Tooltip title="Add to watchlist">
                            <Button
                                variant="outlined"
                                sx={{
                                    // backgroundColor: row.original.watchlist ? '#FFC107' : '',
                                    color: row.original.watchlist
                                        ? 'white'
                                        : '#245372',
                                    borderColor: '#245372',
                                    width: '64px',
                                    height: '24.75px',
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: '#245372'
                                    }
                                }}
                                onClick={() =>
                                    handleAddToWatchlist(
                                        row.original.contract,
                                        'THE'
                                    )
                                }
                                disabled={row.original.watchlist ? true : false}
                            >
                                {row.original.watchlist ? (
                                    <StarIcon
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            fontSize: '18px',
                                            color: '#FFC107'
                                        }}
                                    />
                                ) : (
                                    <StarBorderIcon
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            fontSize: '18px'
                                        }}
                                    />
                                )}
                            </Button>
                        </Tooltip>
                    </Box>
                ],
        [
            disableTradingStatus,
            user,
            handleOpenBuyModal,
            handleOpenSellModal,
            handleAddToWatchlist
        ]
    );

    // filter
    const [filteredData, setFilteredData] = useState([]);

    const [filter, setFilter] = useState({
        typeOfContract: '',
        bidPrice: {
            min: '',
            max: ''
        },
        askPrice: {
            min: '',
            max: ''
        }
    });

    useEffect(() => {
        if (dataWithTS?.length > 0) {
            if (filter.typeOfContract && filter.typeOfContract === 'yearly') {
                setFilteredData(
                    dataWithTS?.filter((item) =>
                        item.contract.includes('CAL')
                    ) || []
                );
            } else if (
                filter.typeOfContract &&
                filter.typeOfContract === 'monthly'
            ) {
                const months = [
                    'JAN',
                    'FEB',
                    'MAR',
                    'APR',
                    'MAY',
                    'JUN',
                    'JUL',
                    'AUG',
                    'SEP',
                    'OCT',
                    'NOV',
                    'DEC'
                ];
                setFilteredData(
                    dataWithTS?.filter((item) =>
                        months.includes(item.contract.slice(0, 3))
                    ) || []
                );
            } else if (
                filter.typeOfContract &&
                filter.typeOfContract === 'quarterly'
            ) {
                setFilteredData(
                    dataWithTS?.filter((item) => item.contract.includes('Q')) ||
                        []
                );
            } else if (
                filter.typeOfContract &&
                filter.typeOfContract === 'winter'
            ) {
                setFilteredData(
                    dataWithTS?.filter((item) =>
                        item.contract.includes('WINTER')
                    ) || []
                );
            } else if (
                filter.typeOfContract &&
                filter.typeOfContract === 'summer'
            ) {
                setFilteredData(
                    dataWithTS?.filter((item) =>
                        item.contract.includes('SUMMER')
                    ) || []
                );
            } else {
                setFilteredData(dataWithTS);
            }
        }
    }, [filter, dataWithTS]);

    useEffect(() => {
        if (dataWithTS?.length > 0) {
            if (filter.bidPrice.min && filter.bidPrice.max) {
                setFilteredData(
                    dataWithTS?.filter(
                        (item) =>
                            item.bid >= filter.bidPrice.min &&
                            item.bid <= filter.bidPrice.max
                    ) || []
                );
            } else if (filter.bidPrice.min) {
                setFilteredData(
                    dataWithTS?.filter(
                        (item) => item.bid >= filter.bidPrice.min
                    ) || []
                );
            } else if (filter.bidPrice.max) {
                setFilteredData(
                    dataWithTS?.filter(
                        (item) => item.bid <= filter.bidPrice.max
                    ) || []
                );
            } else {
                setFilteredData(dataWithTS);
            }
        }
    }, [filter.bidPrice, dataWithTS]);

    useEffect(() => {
        if (dataWithTS?.length > 0) {
            if (filter.askPrice.min && filter.askPrice.max) {
                setFilteredData(
                    dataWithTS?.filter(
                        (item) =>
                            item.ask >= filter.askPrice.min &&
                            item.ask <= filter.askPrice.max
                    ) || []
                );
            } else if (filter.askPrice.min) {
                setFilteredData(
                    dataWithTS?.filter(
                        (item) => item.ask >= filter.askPrice.min
                    ) || []
                );
            } else if (filter.askPrice.max) {
                setFilteredData(
                    dataWithTS?.filter(
                        (item) => item.ask <= filter.askPrice.max
                    ) || []
                );
            } else {
                setFilteredData(dataWithTS);
            }
        }
    }, [filter.askPrice, dataWithTS]);

    return (
        <Box>
            {/* info modal */}
            <Modal open={openInfoModal} onClose={handleCloseInfoModal}>
                <InfoModal
                    handleClose={handleCloseInfoModal}
                    startTime={startTime}
                    endTime={endTime}
                    lunchStart={lunchStart}
                    lunchEnd={lunchEnd}
                />
            </Modal>
            {/* buy modal */}
            <Modal open={openBuyModal} onClose={handleCloseBuyModal}>
                <Buy
                    handleClose={handleCloseBuyModal}
                    open={openBuyModal}
                    theeur={theeur}
                    selectedContract={selectedContract}
                    user={user}
                />
            </Modal>
            {/* sell modal */}
            <Modal open={openSellModal} onClose={handleCloseSellModal}>
                <Sell
                    handleClose={handleCloseSellModal}
                    open={openSellModal}
                    theeur={theeur}
                    selectedContract={selectedContract}
                    user={user}
                />
            </Modal>
            {/* filter modal */}
            <Modal open={openFilterModal} onClose={handleCloseFilterModal}>
                <Filter
                    handleClose={handleCloseFilterModal}
                    open={openFilterModal}
                    filter={filter}
                    setFilter={setFilter}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <Tooltip title="Filter">
                            <IconButton onClick={handleOpenFilterModal}>
                                <FilterAltOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                headerText={'EUR'}
                columns={columns}
                data={filteredData || []}
                actions={actionsMenu}
                handleRowClick={(row) => handleGoToDetails(row.contract)}
                // isLoading={isLoading}
            />
        </Box>
    );
};

export default EUR;
