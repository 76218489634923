import React, { forwardRef, useEffect, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Select, Typography, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiBuySellPowerOrder } from '@store'
import { showToast } from '@utils'

const DeliveryPoint = [
    'CH'
]

const Buy = forwardRef(({ handleClose, selectedContract, user }, ref) => {
    const dispatch = useDispatch()

    const [state, setState] = useState({
        market: 'CH',
        type: 'buy',
        price_selected: '',
        seller: '',
        buyer: user?.CompanyShort,
        deliverypoint: '',
        volume: '',
        exitpoint: '',
        price_percent: '100',
        ordertype: 'internal',
        currency: 'CHF',
        sym: selectedContract.name,
    })

    useEffect(() => {
        if (user?.CanBuyElectricityFrom?.length > 0) {
            setState((prevState) => ({
                ...prevState,
                seller: user.CanBuyElectricityFrom[0]
            }));
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const volume = parseFloat(state.volume)
        const ask = parseFloat(selectedContract.ask)

        const data = {
            ...state,
            volume: volume,
            price: { ask: ask },
        }
        const res = await dispatch(apiBuySellPowerOrder(data))
        if (res?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            showToast('success', 'Order placed successfully')
        } else if (res?.meta?.requestStatus === 'rejected') {
            showToast('error', res.payload)
        }
    }

    // timer
    const [timer, setTimer] = useState(25);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (timer === 0) {
            handleClose();
        }
    }, [timer, handleClose]);

    useEffect(() => {
        if (ref?.current) {
            setTimer(25);
        }
    }, [ref]);

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            timer={timer}
            modalTitle={`Buy Contract`}
            buttonText={'Buy'}
            ref={ref}
            disabled={state.volume === '' || selectedContract.ask === 0 ? true : false}
        >
            <Box className='flex flex-col gap-3'>
                <Box className='flex gap-3'>
                    <Typography>Contract:</Typography>
                    <Typography color={'black'}>{selectedContract.name}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <InputLabel
                        id="contractLabel"
                        sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                    >
                        Volume
                    </InputLabel>
                    <OutlinedInput
                        id="volume"
                        label="Volume"
                        name="volume"
                        required
                        type="number"
                        onChange={handleChange}
                        value={state.volume}
                        inputProps={{ step: 0.01 }}
                        endAdornment={
                            <InputAdornment position="start" sx={{ ml: 2 }}>
                                MW
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Buy From
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Buy From"
                        name="seller"
                        value={state.seller}
                        onChange={handleChange}
                    >
                        {user?.CanBuyElectricityFrom?.map((buyfrom, index) => (
                            <MenuItem key={index} value={buyfrom}>{buyfrom}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Delivery Point
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Delivery Point"
                        name='deliverypoint'
                        value={state.deliverypoint}
                        onChange={handleChange}
                    >
                        {DeliveryPoint?.map((deliverypoint, index) => (
                            <MenuItem key={index} value={deliverypoint}>{deliverypoint}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box className='flex gap-3'>
                    <Typography>Price:</Typography>
                    <Typography color={'black'}>{selectedContract.ask}</Typography>
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Settlement Price:</Typography>
                    <Typography color={'black'}>{selectedContract?.ask * state?.volume || 0}</Typography>
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Currency:</Typography>
                    <Typography color={'black'}>EUR</Typography>
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Last Updated:</Typography>
                    <Typography color={'black'}>{selectedContract?.updated_at_cet_str}</Typography>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default Buy